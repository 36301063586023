import * as Types from '../types';

import { gql } from '@apollo/client';
import { ProductFieldsFragmentDoc } from '../store/_gen/product';
import { SubscriptionPlanProductFragmentDoc } from '../fragments/_gen/subscription-plan-product.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubscriptionPlanFieldsFragment = {
  id: string;
  kind: Types.SubscriptionKind;
  coffeeKind?: Types.ProductCoffeeKind | null;
  name: string;
  minimumPrice: number;
  cta?: string | null;
  description: string;
  discountPercent?: number | null;
  image?: { alt?: string | null; url: string; avif: string; webp: string } | null;
};

export type SubscriptionPlanDetailsFieldsFragment = {
  description: string;
  gift?: {
    quantityStr?: string | null;
    descriptionHtml: string;
    id: string;
    name: string;
    slug: string;
    price: number;
    originalPrice?: number | null;
    isFresh: boolean;
    isFeatured: boolean;
    isOnSale: boolean;
    onSaleDiscount?: number | null;
    clubPrice: number;
    stock: number;
    coffeeKind?: Types.ProductCoffeeKind | null;
    kind: Types.ProductKind;
    quantity: number;
    altitude?: number | null;
    characteristics?: string | null;
    score?: number | null;
    olfactory?: string | null;
    visual?: string | null;
    taste?: string | null;
    unit: Types.ProductUnit;
    metaTitle: string;
    metaDescription: string;
    scaReportUrl?: string | null;
    width?: number | null;
    height?: number | null;
    depth?: number | null;
    weight?: number | null;
    foodPairing?: string | null;
    isSoldOut?: boolean | null;
    grids: Array<{
      id: string;
      stock: number;
      value: { id: string; name: string; option: { id: string; name: string } };
      image?: { url: string; alt?: string | null } | null;
    }>;
    region?: {
      id: string;
      name: string;
      stateCode: string;
      slug: string;
      state: string;
      image?: { url: string; alt?: string | null } | null;
    } | null;
    feedbacks: Array<{
      id: string;
      name: string;
      comment: string;
      subtitle?: string | null;
      avatar?: { url: string; alt?: string | null } | null;
    }>;
    producer?: {
      id: string;
      name: string;
      farm: string;
      slug: string;
      descriptionHtml: string;
      video?: string | null;
      region: { name: string; state: string; stateCode: string };
      images: Array<{
        id: string;
        file?: { alt?: string | null; url: string; avif: string; webp: string } | null;
        original?: { alt?: string | null; url: string; avif: string; webp: string } | null;
        thumbnail?: { alt?: string | null; url: string; avif: string; webp: string } | null;
      }>;
      videoThumbnail?: { alt?: string | null; url: string; avif: string; webp: string } | null;
      avatar?: { alt?: string | null; url: string; avif: string; webp: string } | null;
    } | null;
    variety?: {
      id: string;
      name: string;
      slug: string;
      image?: { url: string; alt?: string | null } | null;
    } | null;
    images: Array<{
      id: string;
      file?: { url: string; alt?: string | null; avif: string; webp: string } | null;
      original?: { url: string; alt?: string | null } | null;
      thumbnail?: { url: string; alt?: string | null; avif: string; webp: string } | null;
    }>;
  } | null;
  products: Array<{
    id: string;
    minimumQuantity: number;
    product: {
      id: string;
      name: string;
      quantity: number;
      quantityStr?: string | null;
      originalPrice?: number | null;
      price: number;
      kind: Types.ProductKind;
      coffeeKind?: Types.ProductCoffeeKind | null;
      stock: number;
      grids: Array<{
        id: string;
        stock: number;
        value: { id: string; name: string; option: { id: string; name: string } };
        image?: { url: string; alt?: string | null } | null;
      }>;
      images: Array<{ file?: { url: string; alt?: string | null } | null }>;
    };
    gridOptionValue?: { id: string; name: string; option: { id: string; name: string } } | null;
    productGrid?: {
      id: string;
      stock: number;
      image?: { url: string; alt?: string | null } | null;
      value: { id: string; name: string; option: { id: string; name: string } };
    } | null;
  }>;
};

export type PlanQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type PlanQuery = {
  plan?: {
    id: string;
    kind: Types.SubscriptionKind;
    coffeeKind?: Types.ProductCoffeeKind | null;
    name: string;
    minimumPrice: number;
    cta?: string | null;
    description: string;
    discountPercent?: number | null;
    image?: { alt?: string | null; url: string; avif: string; webp: string } | null;
    gift?: {
      quantityStr?: string | null;
      descriptionHtml: string;
      id: string;
      name: string;
      slug: string;
      price: number;
      originalPrice?: number | null;
      isFresh: boolean;
      isFeatured: boolean;
      isOnSale: boolean;
      onSaleDiscount?: number | null;
      clubPrice: number;
      stock: number;
      coffeeKind?: Types.ProductCoffeeKind | null;
      kind: Types.ProductKind;
      quantity: number;
      altitude?: number | null;
      characteristics?: string | null;
      score?: number | null;
      olfactory?: string | null;
      visual?: string | null;
      taste?: string | null;
      unit: Types.ProductUnit;
      metaTitle: string;
      metaDescription: string;
      scaReportUrl?: string | null;
      width?: number | null;
      height?: number | null;
      depth?: number | null;
      weight?: number | null;
      foodPairing?: string | null;
      isSoldOut?: boolean | null;
      grids: Array<{
        id: string;
        stock: number;
        value: { id: string; name: string; option: { id: string; name: string } };
        image?: { url: string; alt?: string | null } | null;
      }>;
      region?: {
        id: string;
        name: string;
        stateCode: string;
        slug: string;
        state: string;
        image?: { url: string; alt?: string | null } | null;
      } | null;
      feedbacks: Array<{
        id: string;
        name: string;
        comment: string;
        subtitle?: string | null;
        avatar?: { url: string; alt?: string | null } | null;
      }>;
      producer?: {
        id: string;
        name: string;
        farm: string;
        slug: string;
        descriptionHtml: string;
        video?: string | null;
        region: { name: string; state: string; stateCode: string };
        images: Array<{
          id: string;
          file?: { alt?: string | null; url: string; avif: string; webp: string } | null;
          original?: { alt?: string | null; url: string; avif: string; webp: string } | null;
          thumbnail?: { alt?: string | null; url: string; avif: string; webp: string } | null;
        }>;
        videoThumbnail?: { alt?: string | null; url: string; avif: string; webp: string } | null;
        avatar?: { alt?: string | null; url: string; avif: string; webp: string } | null;
      } | null;
      variety?: {
        id: string;
        name: string;
        slug: string;
        image?: { url: string; alt?: string | null } | null;
      } | null;
      images: Array<{
        id: string;
        file?: { url: string; alt?: string | null; avif: string; webp: string } | null;
        original?: { url: string; alt?: string | null } | null;
        thumbnail?: { url: string; alt?: string | null; avif: string; webp: string } | null;
      }>;
    } | null;
    products: Array<{
      id: string;
      minimumQuantity: number;
      product: {
        id: string;
        name: string;
        quantity: number;
        quantityStr?: string | null;
        originalPrice?: number | null;
        price: number;
        kind: Types.ProductKind;
        coffeeKind?: Types.ProductCoffeeKind | null;
        stock: number;
        grids: Array<{
          id: string;
          stock: number;
          value: { id: string; name: string; option: { id: string; name: string } };
          image?: { url: string; alt?: string | null } | null;
        }>;
        images: Array<{ file?: { url: string; alt?: string | null } | null }>;
      };
      gridOptionValue?: { id: string; name: string; option: { id: string; name: string } } | null;
      productGrid?: {
        id: string;
        stock: number;
        image?: { url: string; alt?: string | null } | null;
        value: { id: string; name: string; option: { id: string; name: string } };
      } | null;
    }>;
  } | null;
};

export type SubscriptionPlansQueryVariables = Types.Exact<{
  coffeeKind?: Types.InputMaybe<Types.ProductCoffeeKind>;
}>;

export type SubscriptionPlansQuery = {
  plans: Array<{
    id: string;
    kind: Types.SubscriptionKind;
    coffeeKind?: Types.ProductCoffeeKind | null;
    name: string;
    minimumPrice: number;
    cta?: string | null;
    description: string;
    discountPercent?: number | null;
    image?: { alt?: string | null; url: string; avif: string; webp: string } | null;
  }>;
};

export const SubscriptionPlanFieldsFragmentDoc = gql`
  fragment subscriptionPlanFields on SubscriptionPlanType {
    id
    kind
    coffeeKind
    name
    minimumPrice
    cta
    description
    discountPercent
    image {
      alt
      url(size: "268")
      avif: url(size: "268", format: AVIF)
      webp: url(size: "268", format: WEBP)
    }
  }
`;
export const SubscriptionPlanDetailsFieldsFragmentDoc = gql`
  fragment subscriptionPlanDetailsFields on SubscriptionPlanType {
    description
    gift {
      ...productFields
    }
    products {
      ...subscriptionPlanProduct
    }
  }
  ${ProductFieldsFragmentDoc}
  ${SubscriptionPlanProductFragmentDoc}
`;
export const PlanDocument = gql`
  query Plan($id: ID!) {
    plan(id: $id) {
      ...subscriptionPlanFields
      ...subscriptionPlanDetailsFields
    }
  }
  ${SubscriptionPlanFieldsFragmentDoc}
  ${SubscriptionPlanDetailsFieldsFragmentDoc}
`;

/**
 * __usePlanQuery__
 *
 * To run a query within a React component, call `usePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlanQuery(baseOptions: Apollo.QueryHookOptions<PlanQuery, PlanQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
}
export function usePlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlanQuery, PlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
}
export type PlanQueryHookResult = ReturnType<typeof usePlanQuery>;
export type PlanLazyQueryHookResult = ReturnType<typeof usePlanLazyQuery>;
export type PlanQueryResult = Apollo.QueryResult<PlanQuery, PlanQueryVariables>;
export const SubscriptionPlansDocument = gql`
  query SubscriptionPlans($coffeeKind: ProductCoffeeKind) {
    plans(coffeeKind: $coffeeKind) {
      ...subscriptionPlanFields
    }
  }
  ${SubscriptionPlanFieldsFragmentDoc}
`;

/**
 * __useSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlansQuery({
 *   variables: {
 *      coffeeKind: // value for 'coffeeKind'
 *   },
 * });
 */
export function useSubscriptionPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(
    SubscriptionPlansDocument,
    options,
  );
}
export function useSubscriptionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionPlansQuery,
    SubscriptionPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(
    SubscriptionPlansDocument,
    options,
  );
}
export type SubscriptionPlansQueryHookResult = ReturnType<typeof useSubscriptionPlansQuery>;
export type SubscriptionPlansLazyQueryHookResult = ReturnType<typeof useSubscriptionPlansLazyQuery>;
export type SubscriptionPlansQueryResult = Apollo.QueryResult<
  SubscriptionPlansQuery,
  SubscriptionPlansQueryVariables
>;
